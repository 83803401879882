export enum BadgeType {
  mustBuy = "mustBuy",
  recommendHighly = "recommendHighly",
  recommendMostly = "recommendMostly",
  recommendFew = "recommendFew",
}

export interface BadgeProps {
  text: string
  icon: boolean
  product: boolean
  badgeType: BadgeType
}
