import { useCallback, useRef } from "react"

import { IntersectionOptions } from "./index"
import { observe } from "./observe"

/**
 * React Hooks make it easy to monitor the `inView` state of your components. Call
 * the `useInView` hook with the (optional) [options](#options) you need. It will
 * return an array containing a `ref`, the `inView` status and the current
 * [`entry`](https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry).
 * Assign the `ref` to the DOM element you want to monitor, and the hook will
 * report the status.
 *
 * @example
 * ```jsx
 * import React from 'react';
 * import { useInView } from 'react-intersection-observer';
 *
 * const Component = () => {
 *   const { ref, inView, entry } = useInView({
 *       threshold: 0,
 *   });
 *
 *   return (
 *     <div ref={ref}>
 *       <h2>{`Header inside viewport ${inView}.`}</h2>
 *     </div>
 *   );
 * };
 * ```
 */
export function useInView({
  threshold,
  delay,
  trackVisibility,
  rootMargin,
  root,
  triggerOnce,
  skip,
  callback,
}: IntersectionOptions = {}): (node?: Element | null) => void {
  const unobserve = useRef<() => void>()
  const triggered = useRef<boolean>(false)
  return useCallback(
    (node) => {
      if (unobserve.current !== undefined) {
        unobserve.current()
        unobserve.current = undefined
      }

      // Skip creating the observer
      if (skip) return

      if (node) {
        unobserve.current = observe(
          node,
          (inView, entry) => {
            if (inView && entry && !(triggerOnce && triggered.current) && !skip) {
              callback()
              triggered.current = true
            }

            if (entry.isIntersecting && triggerOnce && unobserve.current) {
              // If it should only trigger once, unobserve the element after it's inView
              unobserve.current()
              unobserve.current = undefined
            }
          },
          {
            root,
            rootMargin,
            threshold,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            trackVisibility,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delay,
          }
        )
      }
    },
    // We break the rule here, because we aren't including the actual `threshold` variable
    [
      // If the threshold is an array, convert it to a string so it won't change between renders.
      Array.isArray(threshold) ? threshold.toString() : threshold,
      root,
      rootMargin,
      triggerOnce,
      skip,
      trackVisibility,
      delay,
      callback,
    ]
  )
}
