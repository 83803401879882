import React from "react"
import { ReactSVG } from "react-svg"
import * as S from "./styles"
import { BadgeProps, BadgeType } from "./types"

export const Badge: React.FC<BadgeProps> = ({
  text,
  icon = false,
  product = false,
  badgeType = BadgeType.mustBuy,
}) => (
  <>
    <S.Badge product={product} badgeType={badgeType}>
      <S.Content product={product} badgeType={badgeType}>
        {icon && <ReactSVG src="/images/Product/verified_user.svg" />}
        <S.Text product={product} badgeType={badgeType}>
          {text}
        </S.Text>
      </S.Content>
    </S.Badge>
  </>
)
