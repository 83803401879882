import Image from "next/image"
import React from "react"
import { CustomImage } from "../../atoms/CustomImage"

import * as S from "./styles"
import { ProductDropImageShadow, ProductImageProps, ProductImageSize } from "./types"

export const ProductImage: React.FC<ProductImageProps> = ({
  url,
  url2x,
  alt = "no image",
  objectFit = "contain",
  size = ProductImageSize.MEDIUM,
  showBorder = false,
  paddingTopBottom = 0.2,
  paddingSides = 0.2,
  dropShadow = ProductDropImageShadow.none,
  height,
  width,
  product = false,
  priority,
  removeBg = false,
}: ProductImageProps) => {
  if (!url) {
    return (
      <Image
        src="/images/Product/no-photo.svg"
        layout="fill"
        objectFit={objectFit}
        priority={priority}
        alt={alt}
        // placeholder={placeholder}
      />
    )
  }
  return (
    <S.ProductImageContainer
      showBorder={showBorder}
      paddingTopBottom={paddingTopBottom}
      paddingSides={paddingSides}
    >
      <S.Image size={size} dropShadow={dropShadow}>
        {height ? (
          <CustomImage
            priority={priority}
            height={height}
            width={width}
            url={url!}
            url2x={url2x}
            objectFit={objectFit}
            alt={alt}
            removeBg={removeBg}
          >
            <Image src="/images/Product/no-photo.svg" alt={alt} />
          </CustomImage>
        ) : (
          <CustomImage
            url={url!}
            url2x={url2x}
            objectFit={objectFit}
            zoom={product}
            alt={alt}
            removeBg={removeBg}
          >
            <Image src="/images/Product/no-photo.svg" alt={alt} />
          </CustomImage>
        )}
      </S.Image>
    </S.ProductImageContainer>
  )
}
