import {
  ProductDropImageShadow,
  ProductImageSize,
} from "@components/molecules/ProductImage/types"
import styled from "styled-components"

import * as C from "@temp/@next/globalStyles/constants"

const dropShadowMode = {
  height: `${C.productImageContainer.desktop.height}`,
  width: `${C.productImageContainer.desktop.width}`,
  padding: `${C.productImageContainer.desktop.padding}`,
  margin: `${C.productImageContainer.desktop.margin}`,
  dropShadow1: `${C.v2Theme.shadows.dropShadow1}`,
  none: "none",
}

const getHeight = (size: ProductImageSize) => {
  switch (size) {
    case ProductImageSize.LARGE: {
      return "19.44375rem"
    }
    case ProductImageSize.MEDIUM: {
      return "13.90625rem"
    }
    case ProductImageSize.SMALL: {
      return "9.71875rem"
    }
    case ProductImageSize.CART_PAGE: {
      return "3.975rem"
    }
    case ProductImageSize.CART_SUMMARY: {
      return "2rem"
    }
    case ProductImageSize.CART_OVERLAY: {
      return "3.75rem"
    }
    case ProductImageSize.CART_OVERLAY_MOBILE: {
      return "6.375rem"
    }
    case ProductImageSize.CHECKOUT_PAGE_MOBILE: {
      return "2.375rem"
    }
    case ProductImageSize.LARGEV2: {
      return "100%"
    }
    default: {
      return ""
    }
  }
}

const getWidth = (size: ProductImageSize) => {
  switch (size) {
    case ProductImageSize.LARGE: {
      return "14.375rem"
    }
    case ProductImageSize.MEDIUM: {
      return "10.65625rem"
    }
    case ProductImageSize.SMALL: {
      return "7.1875rem"
    }
    case ProductImageSize.CART_PAGE: {
      return "3.975rem"
    }
    case ProductImageSize.CART_SUMMARY: {
      return "2rem"
    }
    case ProductImageSize.CART_OVERLAY: {
      return "2.8rem"
    }
    case ProductImageSize.CART_OVERLAY_MOBILE: {
      return "4.6875rem"
    }
    case ProductImageSize.CHECKOUT_PAGE_MOBILE: {
      return "1.5rem"
    }
    case ProductImageSize.LARGEV2: {
      return "100%"
    }
    default: {
      return ""
    }
  }
}

export const ProductImageContainer = styled.div<{
  showBorder?: boolean
  paddingTopBottom?: number
  paddingSides?: number
}>`
  text-align: center;
  position: relative;
  ${(props) =>
    props.showBorder &&
    `
    border: ${C.theme.border};
    padding: ${props.paddingTopBottom}rem ${props.paddingSides}rem;
  `};
  height: 100%;
  width: 100%;
`
ProductImageContainer.displayName = "S.ProductImageContainer"

export const Image = styled.div<{
  size: ProductImageSize
  dropShadow: ProductDropImageShadow
}>`
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  height: ${(props) => getHeight(props.size)};
  width: ${(props) => getWidth(props.size)};
  filter: ${(props) => dropShadowMode[props.dropShadow]};
`
Image.displayName = "S.Image"
