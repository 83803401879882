import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const ProductImageContainer = styled.div<{
  hasScrollParent?: boolean
  imgHeight?: string
  blur?: string
}>`
  position: relative;
  max-width: ${C.productImageContainer.desktop.width};
  margin: 0px auto;
  height: ${C.productImageContainer.desktop.height};
  opacity: ${(props) => props.blur};
  @media (max-width: ${C.smallScreen}px) {
    height: ${(props) =>
      props.hasScrollParent && props.imgHeight
        ? props.imgHeight
        : C.productImageContainer.mobile.height};
    max-width: ${C.productImageContainer.mobile.width};
  }
`
ProductImageContainer.displayName = "S.ProductImageContainer"

export const ProductName = styled.h4`
  font-family: ${C.baseFontFamily};
  font-size: ${C.fontSizeParagraph1};
  color: ${C.black};
  font-weight: ${C.normalFontWeight};
  margin: ${C.spacing.mediumHalfSpacer} 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  display: flex;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  @media (max-width: ${C.smallScreen}px) {
    margin: ${C.spacing.halfSpacer} 0px;
    display: -webkit-box;
  }
`

ProductName.displayName = "S.ProductName"

export const ProductCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`
ProductCardContainer.displayName = "S.ProductCardContainer"

export const ProductInfo = styled.div`
  margin-top: auto;
`
ProductInfo.displayName = "S.ProductInfo"

export const ProductCategory = styled.h6`
  font-family: ${C.baseFontFamily};
  font-size: ${C.fontSizeCaptions};
  font-weight: ${C.normalFontWeight};
  color: ${C.v2Theme.backgroundColors.gray1};
  text-transform: capitalize;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  @media (max-width: ${C.smallScreen}px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

ProductCategory.displayName = "S.ProductCategory"

export const Divider = styled.hr<{ width: string }>`
  background-color: ${C.dividerColor};
  height: ${C.dividerSize};
  width: ${(props) => props.width};
  border: none;
`

Divider.displayName = "S.Divider"

export const Price = styled.p`
  font-size: ${C.fontSizeCaptions};
  font-family: ${C.baseFontFamily};
  font-weight: ${C.normalFontWeight};
  color: ${C.black};
  line-height: 1;
`

Price.displayName = "S.Price"

export const Mrp = styled.span`
  font-family: ${C.baseFontFamily};
  font-size: ${C.fontSizeCaptions};
  font-weight: ${C.normalFontWeight};
  color: ${C.v2Theme.backgroundColors.gray1};
  margin: 0 ${C.spacing.mediumHalfSpacer} 0 ${C.spacing.halfSpacer};
  @media (max-width: ${C.smallScreen}px) {
    margin: 0 ${C.spacing.halfSpacer} 0 ${C.spacing.quarterSpacer};
  }
  text-align: center;
  text-decoration: line-through;
`

Mrp.displayName = "S.Mrp"

export const Discount = styled.span`
  margin: 0px;
  font-family: ${C.baseFontFamily};
  font-size: 12px;
  font-weight: ${C.normalFontWeight};
  line-height: normal;
  background: #ffebec 0% 0% no-repeat padding-box;
  border: 1px solid #ff5a60;
  border-radius: 6px;
  color: #ff5a60;
  letter-spacing: 0.14px;
  padding: 0px 5px;
  @media (max-width: ${C.smallScreen}px) {
    padding: 0px 3px;
    font-size: 11px;
  }
`
Discount.displayName = "S.Discount"

export const ImagesContainer = styled.div<{ hasScrollParent?: boolean }>`
  position: relative;
  margin-top: ${(props) => (props.hasScrollParent ? "24px" : "15px")};
`
export const CardBadge = styled.div<{ color: string }>`
  height: 52px;
  padding: 4px 11px 24px 11px;
  border-radius: 15px;
  position: absolute;
  background-color: ${(props) => (props.color ? props.color : "blue")};
  top: -64px;
  left: -16px;
  z-index: -1;
  @media (max-width: ${C.smallScreen}px) {
    top: -56px;
    left: -7px;
  }
`

CardBadge.displayName = "S.Cbadge"

export const badgeText = styled.span`
  font-family: ${C.baseFontFamily};
  font-weight: ${C.semiBoldFontWeight};
  font-size: ${C.v2FontSizeParagraph1};
  color: ${C.white};
  width: 112px;
  height: 18px;
  margin: 0 0 1px 0px;
  @media (max-width: ${C.smallScreen}px) {
    font-size: ${C.v2FontSizeCaption};
  }
`
badgeText.displayName = "S.bText"

export const BdImg = styled.img`
  width: 14px;
  height: 18px;
  margin: 1px 7px -5px 0;
  object-fit: contain;
  transform: rotate(-360deg);
  opacity: 0.5;
`
BdImg.displayName = "S.BdImg"

export const Stock = styled.div`
  display: flex;
  font-family: ${C.baseFontFamily};
  font-size: ${C.v2FontSizeCaption};
  font-weight: ${C.normalFontWeight};
  color: ${C.secondaryColor3};
  height: 28px;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  /* margin: 0 0 0 0px; */
  @media (max-width: ${C.smallScreen}px) {
    font-size: 8px;
    height: 22px;
  }
`
Stock.displayName = "S.Stock"

export const Circle = styled.span`
  background-color: ${C.secondaryColor3};
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin: 1px 4px 0px 0px;
`
Circle.displayName = "S.Circle"

export const CardWrapper = styled.div`
  z-index: 1;
`
CardWrapper.displayName = "S.CardWrapper"

export const BrandLogoWrapper = styled.div`
  display: inline-block;
  height: 28px;
  border: 1px solid rgb(219, 226, 234);
  border-radius: 4px;
  vertical-align: middle;
  margin: 0 8px 0 0;
  background-color: #ffffff;
  img {
    padding: 6px !important;
  }
`
BrandLogoWrapper.displayName = "S.BrandLogoWrapper"

export const StockRect = styled.div`
  color: ${C.gray1};
  width: 118px;
  height: 30px;
  padding: 5px 17px;
  border-radius: 11px;
  backdrop-filter: blur(12px);
  background-image: linear-gradient(to bottom, #fefeff, #f8faff 100%);
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 13px;
`
StockRect.displayName = "S.StockRect"

export const SuperSaverWrapper = styled.div`
  position: absolute;
  bottom: 30%;
  @media (max-width: ${C.smallScreen}px) {
    bottom: 34%;
  }
`
SuperSaverWrapper.displayName = "S.SuperSaverWrapper"

export const Ratings = styled.h2`
  font-size: 10px;
  font-weight: ${C.semiBoldFontWeight};
  letter-spacing: 0.12px;
  color: #7687a1;
  margin: 5px 0px 0px 9px;
  min-width: max-content;

  @media (max-width: ${C.smallScreen}px) {
    margin: 2px 0px 0px 9px;
  }
  @media (max-width: ${C.m_smallScreen}px) {
    font-size: 9px;
    margin: 2px 0px 0px 9px;
  }
`
