import { useApolloClient } from "@apollo/client"
import { CardContainer } from "@components/molecules/CardContainer"
import { CardStyle } from "@components/molecules/CardContainer/types"
import { ProductImage } from "@components/molecules/ProductImage"
import {
  ProductDropImageShadow,
  ProductImageSize,
} from "@components/molecules/ProductImage/types"
import {
  comboProductCardFragment,
  productCardFragment,
} from "@temp/@next/components/organisms/ProductCard/queries"
import Link from "@temp/core/link"
import {
  generateComboProductUrl,
  generateProductUrl,
  getDBIdFromGraphqlId,
  GlobalMediaQueryResultInterface,
  trackEvent,
} from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React, { memo, useCallback } from "react"

import { useInView } from "@temp/@next/hooks/useInView"

import { productImageContainer } from "@temp/@next/globalStyles/constants"
import * as S from "./styles"
import { ProductCardProps } from "./types"
import { CustomImage } from "../../atoms/CustomImage"
import { Badge } from "../../atoms/Badge"

enum ProductRecommendationLevel {
  MUST_BUY = "Must Buy",
  HIGHLY_RECOMMENDED = "Highly Recommended",
  SOMEWHAT_RECOMMENDED = "Somewhat Recommended",
}

const ProductCard: React.FC<ProductCardProps> = ({
  productId,
  listId,
  listTitle,
  padding,
  hasScrollParent,
  cardHeight,
  imgHeight,
  priority,
  isComboProduct,
  eventName,
  trackable,
  index,
}: ProductCardProps) => {
  const matches: GlobalMediaQueryResultInterface = getMedia()
  const apolloClient = useApolloClient()

  const product = apolloClient.readFragment({
    id: isComboProduct ? `ComboProduct:${productId}` : `Product:${productId}`,
    fragment: isComboProduct ? comboProductCardFragment : productCardFragment,
  })

  // A function to send the product click event to the data layer
  const sendTrack = () => {
    const ProductClickData = {
      ecommerce: {
        item_list_id: listId,
        item_list_name: listTitle ?? "Homepage",
        items: [
          {
            item_name: product.name,
            item_id: isComboProduct
              ? getDBIdFromGraphqlId(product.id, "ComboProduct")
              : getDBIdFromGraphqlId(product.id, "Product"),
            item_brand: product.brand.name,
            item_category: isComboProduct
              ? product.category.name
              : product.productType.name,
            price: product.pricing.displayPrice.amount,
            index,
            item_type: isComboProduct ? "Combo Product Variation" : "Product Variant",
          },
        ],
      },
    }
    trackEvent({
      eventName: "select_item",
      eventValue: ProductClickData,
      sendToPixel: true,
      sendToDataLayer: true,
    })
  }

  // A function to send the product view event to the data layer
  const callback = useCallback(() => {
    const trackingData = {
      ecommerce: {
        item_list_id: listId,
        item_list_name: listTitle ?? "Homepage",
        items: [{
          item_name: product?.name,
          item_id: product?.id,
          price: product?.pricing!.displayPrice!.amount,
          item_brand: product?.brand!.name,
          item_category: product?.category!.name,
          index: index,
          item_type: isComboProduct ? "Combo Product Variation" : "Product Variant",
        }],
      },
    }
    trackEvent({
      eventName,
      eventValue: trackingData,
      sendToPixel: true,
      sendToDataLayer: true,
    })
  }, [
    listTitle,
    eventName,
    product?.brand,
    product?.category,
    product?.id,
    product?.name,
    product?.pricing,
  ])

  const cardReference = useInView({
    skip: !trackable,
    triggerOnce: true,
    threshold: 0.75,
    callback,
  })

  const listPrice = product?.pricing?.displayListPrice!.amount
  const displayPrice = product?.pricing?.displayPrice!.amount
  const discountPercent = Math.trunc(((listPrice - displayPrice) / listPrice) * 100)
  return (
    <>
      {product && (
        <S.CardWrapper ref={cardReference}>
          <CardContainer
            hasScrollParent={hasScrollParent}
            cardHeight={cardHeight}
            padding={padding}
            cardType={CardStyle.productCard}
          >
            <Link
              href={
                isComboProduct
                  ? generateComboProductUrl(product.id, product.name)
                  : generateProductUrl(product.id, product.name)
              }
            >
              <a
                target={matches.large ? "_blank" : "_self"}
                rel={matches.large ? "noopener noreferrer" : ""}
                onClick={() => sendTrack()}
              >
                <S.Stock>
                  {/* {product.stockQuantity < 5 && product.stockQuantity > 0 ? (
                    <span>
                      <S.Circle />
                      {product.id === "UHJvZHVjdDo4MQ=="
                        ? `Limited Deal (Only ${product.stockQuantity} in stock)`
                        : `Only ${product.stockQuantity} in stock`}
                    </span>
                  ) : null} */}
                  {!isComboProduct && product?.recommendationLevel && (
                    <Badge
                      icon={product?.recommendationLevel === "MUST_BUY"}
                      text={ProductRecommendationLevel[product?.recommendationLevel]}
                    />
                  )}
                  {!isComboProduct && product?.ratingsCount > 0 && (
                    <S.Ratings>{`${product?.ratingsCount} ${product?.ratingsCount > 1 ? "Ratings" : "Rating"
                      }`}</S.Ratings>
                  )}
                </S.Stock>
                <S.ProductCardContainer>
                  {product.badge ? (
                    <S.CardBadge
                      color={product.badge?.color ? product.badge?.color : "#00a1ff"}
                    >
                      <S.badgeText>
                        {product.badge?.name === "Buyceps Choice" && (
                          <S.BdImg src="/images/listing-pages/bg-buyceps-logo-2.svg" />
                        )}
                        {product.badge?.name}
                      </S.badgeText>
                    </S.CardBadge>
                  ) : null}
                  <S.ImagesContainer hasScrollParent={hasScrollParent}>
                    <S.ProductImageContainer
                      imgHeight={`${imgHeight}px`}
                      hasScrollParent={hasScrollParent}
                      blur={
                        product.stockQuantity === 0 && product.neStockQuantity === 0
                          ? "0.25"
                          : "1"
                      }
                    >
                      <ProductImage
                        height={
                          matches.small
                            ? imgHeight || productImageContainer.mobile.heightNumber
                            : productImageContainer.desktop.heightNumber
                        }
                        width={
                          matches.small
                            ? productImageContainer.mobile.widthNumber
                            : productImageContainer.desktop.widthNumber
                        }
                        size={ProductImageSize.LARGEV2}
                        url={product.firstImage?.url}
                        url2x={product.firstImage?.url}
                        alt={product.firstImage?.alt}
                        discountValue={discountPercent}
                        objectFit="contain"
                        dropShadow={ProductDropImageShadow.dropShadow1}
                        product={false}
                        priority={!!priority}
                        removeBg
                      />
                    </S.ProductImageContainer>
                    {product.stockQuantity === 0 &&
                      product.neStockQuantity === 0 && (
                        <S.StockRect>Out Of Stock</S.StockRect>
                      )}
                  </S.ImagesContainer>
                  {product.superSaver && product.stockQuantity !== 0 ? (
                    <S.SuperSaverWrapper>
                      <CustomImage
                        url="/images/Product/supersaver.svg"
                        width={65}
                        height={65}
                        layout="fixed"
                        alt="Super Saver"
                      />
                    </S.SuperSaverWrapper>
                  ) : null}
                  <S.ProductInfo>
                    <S.ProductCategory>
                      {product.brand.logo?.url && (
                        <S.BrandLogoWrapper>
                          <CustomImage
                            height={26}
                            width={46}
                            url={product.brand.logo?.url}
                            layout="fixed"
                            alt={`${product.brand.name} logo`}
                          />
                        </S.BrandLogoWrapper>
                      )}
                      {product.category.name.length > 13
                        ? `${product.category.name.slice(0, 13)}...`
                        : product.category.name}
                    </S.ProductCategory>
                    <S.ProductName>{product.name}</S.ProductName>
                    {discountPercent > 0 ? (
                      <S.Price>
                        ₹{Math.floor(displayPrice)}
                        <S.Mrp>₹{listPrice}</S.Mrp>
                        <S.Discount>{discountPercent}% off</S.Discount>
                      </S.Price>
                    ) : (
                      <S.Price>₹{displayPrice}</S.Price>
                    )}
                  </S.ProductInfo>
                </S.ProductCardContainer>
              </a>
            </Link>
          </CardContainer>
        </S.CardWrapper>
      )}
    </>
  )
}

export const MemoizedProductCard = memo(ProductCard)
