export enum ProductImageSize {
  LARGE,
  MEDIUM,
  SMALL,
  CART_PAGE,
  CART_SUMMARY,
  CART_OVERLAY,
  CART_OVERLAY_MOBILE,
  CHECKOUT_PAGE_MOBILE,
  LARGEV2,
}

export enum ProductDropImageShadow {
  dropShadow1 = "dropShadow1",
  none = "none",
}

export interface ProductImageProps {
  size: ProductImageSize
  url?: string
  url2x?: string
  alt?: string
  discountValue?: number
  objectFit?: string
  showBorder?: boolean
  paddingTopBottom?: number
  paddingSides?: number
  dropShadow?: ProductDropImageShadow
  height?: number
  width?: number
  product?: boolean
  priority: boolean
  removeBg?: boolean
}
