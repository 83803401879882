import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"
import { BadgeType } from "./types"

const badgeStyleType: { [key: string]: any } = {
  mustBuy: {
    color: "#33B95E",
    background: "#DBFFE5",
    backgroundFill: "#33B95E",
  },
  recommendHighly: {
    color: "#33B95E",
    background: "#DBFFE5",
  },
  recommendMostly: {
    color: "#B98533",
    background: "#FFF9DB",
  },
  recommendFew: {
    color: "#B98533",
    background: "#FFF9DB",
  },
}

export const Badge = styled.div<{ badgeType: BadgeType; product: boolean }>`
  display: flex;
  border-radius: 10px;
  align-items: center;

  ${({ product }) =>
    product
      ? `height: 24px;
         align-self: center;`
      : null}
  ${({ badgeType, product }) =>
    `background: ${
      product
        ? badgeStyleType[badgeType].backgroundFill
        : badgeStyleType[badgeType].background
    };`}
  padding: ${({ product }) => (product ? "0px 10px 0px 10px" : "0px 8px 0px 8px")};
  @media (max-width: ${C.smallScreen}px) {
    padding: 0px 8px 0px 8px;
    margin-right: ${({ product }) => (product ? "7px" : "0px;")};
  }
`

export const Content = styled.div<{ badgeType: BadgeType; product: boolean }>`
  display: flex;
  align-items: center;
  width: max-content;
  svg {
    display: block;
    margin-right: 5px;
    @media (max-width: ${C.m_smallScreen}px) {
      margin-right: 2px;
    }
    @media (max-width: ${C.smallScreen}px) {
      height: 13px;
    }
    path {
      ${({ badgeType, product }) =>
        `fill: ${product ? "#fff" : badgeStyleType[badgeType].color};`}
    }
  }
`
export const Text = styled.span<{ badgeType: BadgeType; product: boolean }>`
  ${({ badgeType, product }) =>
    `color: ${product ? "#fff" : badgeStyleType[badgeType].color};`}
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.14px;
  @media (max-width: ${C.smallScreen}px) {
    font-size: 10px;
  }
  @media (max-width: ${C.m_smallScreen}px) {
    letter-spacing: 0px;
  }
`
